.signedpages {
  background-color: white;
  display: flex;
  justify-content: space-between;
  
}

.sign-in-page {
  margin-top: 70px;
  margin-left: 140px;
  min-width: 572px;
  max-width: 600px;
}

.ssoLoading {
  backdrop-filter: blur(4px);
  position: absolute;
  width: 50%;
  height: 100%;
  margin-left: -10px;
  margin-top: -10px;
}

.ssoLoading div {
  position: absolute;
  left: 20%;
  top: 50%;
}

.sign-up-page {
  margin-top: 70px;
  margin-left: 140px;
  min-width: 575px;
  max-width: 1000px;
  

}

.signedpages > img {
  height: 100vh;
}

.signed-header {
  font-size: 40px;
  font-weight: 700;
  margin-top: 50px;
}

.signed-sub-header {
  font-size: 25px;
  margin-top: 25px;
}

.sign-in-page-section {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
}

.sign-up-page-section {
  display: flex;
  flex-wrap: wrap;
  margin-top: 30px;
}

.sign-up-page-section input {
  margin-right: 15px;
}


input[type="button"] {
  background-color: #004225;
  color: white;
  max-width: 300px;
  padding: 0;
  text-transform: uppercase;
  font-family: Roboto, Rubik, sans-serif;
}

.sso {
  background-color: #2f2f2f !important;
  color: white !important;
  width: 400px;
}

.sso img {
  width: 200px;
  float: left;
}

.signed-pages-links-password {
  display: flex;
  justify-content: flex-end;
  margin-right: 50px;
  margin-top: -20px;
  font-style: italic;
}

.signed-pages-links-password a {
  color: #80919a;
}

.signed-pages-links {
  margin-top: -20px;
  font-style: italic;
  color: #80919a;
}

.signed-pages-links a {
  color: black;
}

.input-text {
  height: 80px;
  max-width: 522px;
  min-width: 400px;
  /* UI Properties */
  border: 1px solid #004225;
  border-radius: 2px;
  margin-bottom: 30px;
  font-size: 25px;
  color: black;
  padding-left: 40px;
}

::placeholder {
  color: #b0bec5;
}

button.resetBtn {
  background-color: #004225;
  color: white;
  padding: 0;
  text-transform: uppercase;
  font-family: Roboto, sans-serif;
  height: 80px;
  max-width: 522px;
  min-width: 400px;
  /* UI Properties */
  border: 1px solid #004225;
  border-radius: 23px;
  margin-bottom: 30px;
  font-size: 25px;
 
  
}

.wisdom-logo-img{
  width: 310px;
  height: 130px;
}

.signIn-btns{
  display: flex;
  align-items: center;
  gap: 1rem;
}

.signIn-btns button{
  border-radius: 2px;
  font-weight: bolder;
}

.signIn-btns button:not(:disabled){
  transition: all .2s ease;
}

.signIn-btns .signIn-login-btn:disabled{
  background-color: #80919a;
}

.signIn-btns .signIn-login-btn:not(:disabled):hover{
  background-color: #0f6d44;
}

.signIn-btns .signIn-sso-btn:not(:disabled):hover{
  background-color: #5c5252;
}

.signIn-login-btn{
  background-color: #004225;
  font-size: 1.5rem;
  color: white;
  padding: 10px 15px;
}

.signIn-sso-btn{
  background-color: #2f2f2f;
  font-size: 1.5rem;
  color: white;
  padding: 10px 15px;
}


