@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Rubik&display=swap");

* {
  box-sizing: border-box;
}

html {
  font-family: Rubik, sans-serif;
  font-style: normal;
  font-weight: normal;
  height: 100%;
}

::-webkit-scrollbar {
  width: 5px;
  background-color: #F1F1F1;
}

::-webkit-scrollbar-thumb{
  background-color: #c0c0c0;
}

body {
  margin: 0;
  overflow-x: hidden;
}

a {
  text-decoration: none;
}

button{
  outline: none;
  border: none;
}

.time-card-modal {
  box-shadow: 0px 2px 15px #00000019;
  border-radius: 25px;
  background-color: white;
  padding: 1rem;
  z-index: 10;
  position: fixed;
  top: 10vh;
  left: 10vw;
}

.form-modal {
  box-shadow: 0px 2px 15px #00000019;
  border-radius: 2px;
  background-color: white;
  z-index: 10;
  position: fixed;
  top: 30vh;
  left: 30vw;
  display: flex;
}

.backdrop {
  position: fixed;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
}

.landing-page {
  background-color: white;
  height: 100vh;
}
