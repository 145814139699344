.employees-table-action-btns{
    display: flex;
    align-items: flex-start;
    gap: .5rem;
}

.talent-pool-table-header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.employees-table-action-btns button{
    font-size: 1.2rem;
    background-color: transparent;
    padding: 1rem;
}

.employees-table-edit-btns{
    display: flex;
    align-items: flex-start;
    gap: .5rem;
}

.employees-table-edit-btns button{
    font-size: 1.2rem;
    background-color: transparent;
    padding: 1rem;
}

.admin-add-employee-dropdow-box{
    margin: 2rem;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.add-employee-dialog{
    display: flex;
    flex-direction: column;
}
.add-employee-dialog .add-employee-dialog-input{
    display: flex;
    align-items: flex-start;
    padding: .5rem;
    gap: 1rem;
}

.add-employee-dialog .add-employee-dialog-input .field{
    display: flex;
    flex-direction: column;
}

.add-employee-dialog .add-employee-dialog-input .add-employee-dialog-input-file{
    width: 100%;
    display: flex;
    gap: .5rem;
    align-items: center;
    justify-content: center;
    padding: .5rem;
}

.add-employee-dialog .add-employee-dialog-input .add-employee-dialog-input-text{
    border: 1px solid #A5F3FC;
    width: 300px;
}
