.reject-reason-box{
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(68, 68, 68, 0.096);
  z-index: 999;
}


.reject-reason-modal{
  box-shadow: 3px 3px 3px 3px rgba(0, 0, 0, 0.253);
  border-radius: 2px;
  padding: 20px 15px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
}

.reject-reason-btns{
  display: flex;
  align-items: center;
  gap: 8px;
}

.reject-reason-save-btn{
  background-color: #004225;
  color: white;
  font-weight: bolder;
  padding: 13px 20px;
  border-radius: 2px;
}
.reject-reason-cancel-btn{
  background-color: #910000;
  color: white;
  font-weight: bolder;
  padding: 13px 15px;
  border-radius: 2px;
}

.reason-selector{
  width: 300px;
  text-align: center;
  padding: 13px 0;
  font-weight: bolder;
}

.reject-reason-save-btn:disabled{
  background-color: gray;
  color: white;
}