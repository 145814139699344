.confirmation-modal {
  box-shadow: 0px 2px 15px #00000019;
  border-radius: 25px;
  background-color: white;
  padding: 1rem;
  z-index: 10;
  position: fixed;
  top: 30vh;
  left: 30vw;
}

.confirmation-modal-text {
  margin: 10px;
}

.confirmation-modal-button {
  margin: 10px;
  color: white;
  padding: 10px 15px;
  border-radius: 10px;
}

.confirmation-modal-button.cancel {
  background-color: red;
  border: none;
}

.confirmation-modal-button.confirm {
  background-color: #004225;
  border: none;
}
