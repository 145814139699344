.filters{
    padding: 40px;
}

.update-timecards-box{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
}

.timecards-selection{
    margin-top: 8px;
    width: 100%;
}

.confirm-timecards-update-btn{
  background-color: #3560C8;
  color: white;
}
.confirm-timecards-update-btn:hover{
  background-color: #4173e9;
}

.confirm-timecards-update-btn:disabled{
    background-color: rgb(175, 175, 175);
}

.update-timecards-info-text{
    font-size: 14px;
    font-weight: bolder;
    color: red;
}