.EmployeeShortProfile {
  display: flex;
  flex-wrap: wrap;
  padding: 10px 0;
}

.summaryProfileImage {
  border-radius: 44px;
  height: 165px;
  width: 180px;
  margin-bottom: 10px;
  object-fit: cover
}

.test::after{
  content: attr(test);
}

.image-box{
  box-sizing: border-box;
  position: relative;
  display: inline-block;
}

.icone-cloud-upload {
  cursor: pointer;
  position: absolute;
  bottom: 0;
  right: 0;
  margin: 5px;
  display: flex;
  align-items: center;
  background-color: white;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}


.summaryProfileInformation {
  margin-left: 25px;
  display: flex;
  flex-direction: column;
}

.summaryProfile-name {
  font-size: 36px;
  font-weight: 700;
}

.summaryProfile-designation,
.summaryProfile-ID {
  font-size: 20px;
  font-weight: 300;
}

.summaryProfile-ID {
  margin-bottom: 20px;
}

.summaryProfile-status,
.summaryProfile-location {
  font-size: 16px;
}

.summaryProfile-pronoun{
  font-weight: lighter;
  font-size: 30px;
}


/* UPLOAD MODAL  */

.able{
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  z-index: 9999;
}
.modal-inputs{
  display: flex;
  align-items: center;
}

.uploaded-image{
  border-radius: 44px;
  height: 165px;
  width: 180px;
}

.modal-form{
  padding: 10px;
}

.modal-btns{
  margin-top: 16px;
  display: flex;
  align-items: center;
}

.save-profile-picture-btn{
  border: none;
  border-radius: 2px;
  outline: none;
  color: white;
  background-color: #004225;
  padding: 10px;
  font-weight: bolder;
  margin-right: 4px;
}

.close-upload-profile-picture-modal-btn{
  border: none;
  outline: none;
  color: white;
  background-color: rgb(136, 23, 23);
  padding: 10px;
  font-weight: bolder;
  border-radius: 2px;
  margin-left: 4px;
}

.disable{
  display: none;
}

.modal-content{
  border-radius: 2px;
  padding: 20px;
  width: 30%;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  
  -webkit-box-shadow: 0px 3px 24px 0px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 3px 24px 0px rgba(0,0,0,0.75);
  box-shadow: 0px 3px 24px 0px rgba(0,0,0,0.75);
}

.user-status-box{
  display: flex;
  align-items: center;
  gap: 1rem;
}

.user-status-btns{
  display: flex;
  align-items: center;
  gap: .5rem;
}

.user-status-btns .summaryProfile-renewContract{
  text-decoration: none;
  padding: 10px 15px;
  color: white;
  font-weight: bolder;
  background-color: #004225;
  border-radius: 2px;
  transition: all .2s ease;
}

.user-status-btns .summaryProfile-renewContract:hover{
  text-decoration: none;
  padding: 10px 15px;
  color: white;
  font-weight: bolder;
  background-color: #026b3e;
  border-radius: 2px;
}

.summaryProfile-disabled{
  font-weight: bolder;
  color: rgb(45, 45, 45);
}

.user-status-btns .summaryProfile-offboarding{
  cursor: pointer;
  text-decoration: none;
  background-color: #CC3300;
  padding: 10px 15px;
  border-radius: 2px;
  color: white;
  font-weight: bolder;
  transition: all .2s ease;
}

.user-status-btns .summaryProfile-offboarding:hover{
  background-color: #ca481c;
  color: white;
}

@media screen and (max-width: 1600px) {
  .modal-content {
    width: 50%;
  }
}

@media screen and (max-width: 900px) {
  .modal-content {
    width: 90%;
  }
}