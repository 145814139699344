.resume-modal{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.404);
    border-radius: 45px 0 0 0;
    display: flex;
    align-items: center;
    justify-content: center;
}   

.resume-modal .resume-container{
    background-color: white;
    border: 2px solid rgba(0, 0, 0, 0.445);
    padding: 1rem;
    border-radius: 2px;
    text-align: center;
}


.resume-modal .resume-container .resume-form{
    display: flex;
    align-items: center;
    gap: 1rem;
}

.resume-modal .resume-container .resume-form button{
    border-radius: 2px;
    padding: .5rem 1rem;
    font-weight: bolder;
    color: white;
    transition: all .2s ease;
}

.resume-modal .resume-container .resume-form .resume-form-upload-btn{
    background-color: #004225;
}

.resume-modal .resume-container .resume-form .resume-form-upload-btn:disabled{
    background-color: rgb(184, 184, 184);
}

.resume-modal .resume-container .resume-form .resume-form-cancel-btn{
    background-color: rgb(211, 71, 20);
}

.resume-modal .resume-container .resume-form .resume-form-upload-btn:not(:disabled):hover{
    background-color: #095836;
    
}

.resume-modal .resume-container .resume-form .resume-form-cancel-btn:not(:disabled):hover{
    background-color: rgb(202, 92, 52);
}

.file-max-size-alert{
    text-transform: lowercase;
    font-weight: bolder;
}