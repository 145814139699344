.user-role-dropdown{
    display: flex;
    align-items: center;
    gap: 8px;
}

.user-role-fields div {
    margin: 4px 0;
}

.user-role-fields .user-role-btn-group{
    display: flex;
    align-items: center;
    gap: 8px;
}

.user-role-fields .user-role-btn-grou button{
    transition: background-color .2s ease-in;
}

.user-role-fields .user-role-btn-group .user-role-update-btn{
    padding: 10px 15px;
    border-radius: 2px;
    background-color: #004225;
    color: white;
    cursor: pointer;
}

.user-role-fields .user-role-btn-group .user-role-update-btn:hover{
    background-color: #026439;
}

.user-role-fields .user-role-btn-group .user-role-cancel-btn{
    background-color: #D34714;
    color: white;
    cursor: pointer;
}

.user-role-fields .user-role-btn-group .user-role-cancel-btn:hover{
    background-color: #f8571c;
}

.user-role-fields .user-role-btn-group .user-role-update-btn:disabled{
    background-color: gray;
    color: black;
    cursor: default;
}