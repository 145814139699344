.profileMain {
  background-color: white;
  top: 100px;
  left: 75px;
  position: absolute;
  border-radius: 45px 0 0 0;
  width: calc(100vw - 75px);
  overflow-y: scroll;
  height: calc(100% - 10vh);
}
