.summaryCard {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 15px #00000029;
  border-radius: 25px;
  width: 200px;
  height: 130px;
  margin-top: 30px;
  margin-right: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  cursor: default;
}

.summaryCardTitle {
  font-size: 25px;
  color: black;
  font-weight: 600;
}

.summaryCardValue {
  font-size: 30px;
  font-weight: 300;
  color: black;
}
