.compliance-settings-form{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
}
.compliance-settings-form .compliance-settings-read-me{
    font-weight: bolder;
    font-size: .9rem;
}

.compliance-settings-form div{
    display: flex;
    align-items: center;
    gap: .5rem;
}

.compliance-settings-form div label{
    font-weight: bolder;
}

.compliance-settings-form div input{
    border: 2px solid #808080;
    border-radius: 2px;
    padding: .5rem;
}

.compliance-settings-save-btn{
    background-color: #004225;
    border-radius: 2px;
    padding: .5rem 1rem;
    color: white;
}

.compliance-settings-save-btn:disabled{
    background-color: rgb(190, 190, 190);
}