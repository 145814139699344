.trolley-dropdown{
    margin-left: 5px;
    width: 350px;
    line-height: 2.4;
}

.project-details-card{
    margin-top: 32px;
}

.generate-trolley-report-btn{
    padding: 10px 15px;
    margin-top: 1rem;
    margin-left: 5px;
    border-radius: 2px;
    color: white;
    font-weight: bolder;
    background-color: #216BA5;
    transition: all .2s ease;
}

.generate-trolley-report-btn:hover{
    background-color: #2987CE;
}

.generate-trolley-report-btn:disabled{
    background-color: gray;
    color: white;
}

.trolley-report-date-select{
    line-height: 3.0;
}