.manualtime-box{
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.185);
  z-index: 8000;
}

.overtime-box{
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.185);
  z-index: 8000;
}

.overtime-box .overtime-modal{
  text-align: center;
  background-color: white;
  z-index: 9999;
  padding: 10px 15px;
  border-radius: 2px;
  margin-top: -20rem;
}

.manualtime-box .manualtime-modal{
  text-align: center;
  background-color: white;
  z-index: 9999;
  padding: 10px 15px;
  border-radius: 2px;
  margin-top: -20rem;
}

.form-submit-button {
  width: 150px;
  height: 52px;
  background: #004225 0% 0% no-repeat padding-box;
  border: 1px solid #004225;
  border-radius: 35px;
  margin-left: 585px;
  margin-top: 20px;
  color: white;
  text-align: center;
  padding-top: 15px;
  cursor: pointer;
}

.form-modal .Timeform {
  padding: 1em;
}

.close-modal {
  margin-left: 700px;
}

.manual-form-modal{
  display: flex;
  align-items: center;
  gap: 	1rem;
}

.mt-form-btns{
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 16px;
}

.mt-form-btns button{
  border: none;
  outline: none;
  padding: 10px 15px;
  color: white;
  border-radius: 2px;
  font-weight: bolder;
  transition: all .3s ease-in-out;
}

.mt-form-btns .create-mt-btn{
  background-color: #004225;
}

.mt-form-btns .create-mt-btn:hover{
  background-color: #026b3e;
}

.mt-form-btns .cancel-mt-btn{
  background-color: #9b0e0e;
}

.mt-form-btns .cancel-mt-btn:hover{
  background-color: #c42c2c;
}


.overtime-modal-btns{
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 16px;
}

.overtime-modal-btns button{
  border: none;
  outline: none;
  padding: 10px 15px;
  color: white;
  border-radius: 2px;
  font-weight: bolder;
  transition: all .3s ease-in-out;
}

.overtime-modal-btns .create-overtime-btn{
  background-color: #004225;
}

.overtime-modal-btns .create-overtime-btn:hover{
  background-color: #026b3e;
}

.overtime-modal-btns .cancel-overtime-btn{
  background-color: #9b0e0e;
}

.overtime-modal-btns .cancel-overtime-btn:hover{
  background-color: #c42c2c;
}

@media only screen and (max-width: 1110px) {
  .manualtime-box .manualtime-modal{
      margin-top: -20rem;
  }

  .overtime-box .overtime-modal{
    margin-top: -20rem;
  }

}