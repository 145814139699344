.dashboardProjectsMain {
  background-color: white;
  top: 100px;
  left: 75px;
  position: absolute;
  border-radius: 45px 0 0 0;
  width: calc(100vw - 75px);
  height: calc(100% - 10vh);
  overflow-y: scroll;
  padding: 10px 40px;
}

.shared svg {
  color: green;
}

.offline svg {
  color: red;
}


.dashboardProjectsMain .project-details-buttons {
  background-color: #fff;
  border-radius: 15px;
  margin: 10px 20px;
  color: #004225;
  text-align: center;
  font-size: 20px;
  padding: 5px 20px;
  text-transform: capitalize;
  border: 2px solid #004225;
}

.project-add-button {
  color: white;
  background-color: #004225;
  padding: 10px 20px;
  border-radius: 15px;
  font-size: 25px;
  margin: 0 0 0 20px;
  border: 2px solid #004225;
}

.dashboardProjectsMain-header {
  font-size: 40px;
  padding-left: 15px;
  border-left: 3px solid black;
  margin: 30px 0;
}

.projects-onlyActive{
  margin: 16px 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.manual-hours-buttons button{
  color: white;
  padding: .5rem;
  border-radius: 2px;
}

.reject-button{
  background-color: red;
}


.approve-button{
  background-color: #004225;
}
