.announcement-form{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
}

.announcement-form label{
    font-weight: bolder;
}

.announcement-form textarea{
    padding: 10px;
    outline: none;
    border-radius: 2px;
}

.announcement-form .announcements-btns{
    display: flex;
    align-items: center;
    gap: 16px;
}

.announcement-form .announcements-btns button{
    transition: all .2s ease-in-out;
    padding: 10px 15px;
    border-radius: 2px;
}

.announcement-form .announcements-btns .save-announcements-btn{
    background-color: #004225;
    color: white;
}

.announcement-form .announcements-btns .save-announcements-btn:disabled{
    background-color: rgb(90, 90, 90);
}

.announcement-form .announcements-btns .save-announcements-btn:disabled:hover{
    background-color: rgb(90, 90, 90);
}



.announcement-form .announcements-btns .save-announcements-btn:hover{
    background-color: #0a663e;
}

.announcement-form .announcements-btns .clear-announcements-btn{
    background-color: rgb(211, 71, 20);
    color: white;
}


.announcement-form .announcements-btns .clear-announcements-btn:hover{
    background-color: rgb(236, 106, 58);
    color: white;
}