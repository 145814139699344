.custom-select-container {
  border: solid 1px #004225;
  border-radius: 2px;
  padding: 0 10px;
  margin: 0 5px 5px;
  height: 60px;
  width: 350px;
  font-size: 20px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-between;
}

.selected-text {
  padding: 5px;
  margin-top: 17px;
}

.selected-text::after {
  content: "";
  position: relative;
  cursor: pointer;
  right: calc(100% - 325px);
  top: 15px;
  border: 7px solid transparent;
  border-color: #004225 transparent transparent transparent;
}

.selected-text.active::after {
  top: -17px;
  border-color: transparent transparent #004225 transparent;
  position: relative;
  
}

ul.select-options {
  border: 1px solid black;
  margin-top: 7px;
  margin-left: 7px;
  padding: 0;
  text-align: center;
  background-color: white;
  width: 95%;
  border-top: none;
  z-index: 20;
}

ul.select-options li {
  list-style-type: none;
  padding: 6px 20px;
  cursor: pointer;
  background-color: white;
  color: black;
}

ul.select-options li:hover {
  background-color: #CFF4FC;
}

