.compliances{
    background-color: white;
    top: 100px;
    left: 75px;
    position: absolute;
    border-radius: 45px 0 0 0;
    width: calc(100vw - 75px);
    min-height: calc(100vh - 100px);
    display: flex;
}

.compliance-main-content{
    margin: 0;
    width: 90%;
}

.compliances-table{
    padding: 1rem 2rem;
    width: 90%;
    cursor: default;
}

.compliance-detail-col{
    text-align: center;
}

.compliance-detail-btn{
    background-color: transparent;
    font-size: 1.5rem;
    text-align: center;
    padding: 10px;
}
