.summary {
    background-color: white;
    top: 100px;
    left: 75px;
    position: absolute;
    border-radius: 45px 0 0 0;
    width: calc(100vw - 75px);
    min-height: calc(100vh - 100px);
    display: flex;
    overflow: scroll;
  }
  
  .summaryMain {
    overflow-x: hidden;
    height: calc(100vh - 220px);
    margin-left: 1rem;
  }
  .manual-hours {
    position: absolute;
    width: 90%;
    left: 80px;
  }
  
  .manual-hour-form-button {
    width: 200px;
    height: 66px;
    background-color: #004225;
    border-radius: 18px;
    margin-left: 78vw;
    margin-bottom: 25px;
    color: #fff;
    text-align: center;
    font-size: 25px;
    font-family: Roboto, sans-serif;
    padding-top: 15px;
  }
  
  .requested {
    color: gray;
    font-size: 20px;
    font-weight: 700;
    justify-content: center;
  }
  
  .approved {
    color: #004225;
    font-size: 20px;
    font-weight: 700;
    justify-content: center;
  }
  
  .rejected {
    color: red;
    font-size: 20px;
    font-weight: 700;
    justify-content: center;
  }

  .cancelled {
    color: grey;
    font-size: 20px;
    font-weight: 700;
    justify-content: center;
  }
  
  .manual-hours-buttons button {
    margin: 0 5px;
  }
  
  .ot-hours{
    margin-top: 46px;
  }

  .users-request-summaryContent{
    margin-top: 2rem;
  }
