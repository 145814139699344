.team-member {
  background-color: white;
  top: 100px;
  left: 75px;
  position: absolute;
  border-radius: 45px 0 0 0;
  width: calc(100vw - 75px);
  min-height: 100%;
  display: flex;
  overflow: scroll;
}

.summaryMain {
  overflow-x: hidden;
  height: calc(100vh - 200px);
  margin-left: 1rem;
}

.team-member .searchText {
  left: -180px;
}

.team-member .EmployeeShortProfile {
  margin-top: 50px;
  margin-left: 62px;
  padding-bottom: 20px;
  border-bottom: none;
}

.summaryDiary-user.teamMember {
  top: 230px;
}

.summaryHeader.teamMember {
  padding: 0;
  margin: 10px 0;
}

.selectors.teamMember {
  width: auto;
}

.selectors-row.teamMember .summaryCards.teamMember {
  margin-left: 20px;
}

.selectors-row.teamMember {
  flex-wrap: nowrap;
  align-items: center;
}

.teamMember .summaryCard {
  margin-bottom: 20px;
}

.diaryHeader.teamMember {
  display: flex;
  justify-content: flex-start;
  margin-top: 20px;
}

.teamMember .diarySummary {
  height: calc(100vh - 675px);
}

.summaryContent.teamMember {
  height: fit-content;
  border-bottom: 2px black solid;
}

.diaryHeader .summaryHeader.teamMember {
  margin-right: 30px;
}


.dailyDiaryContainer{
  width: 850px;
}
