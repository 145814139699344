
.employee-summary-main{
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
}

.my-dashboard-header{
  width: calc(100vw - 5vw);
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 16px;
  background-color: #02fa8e0c;
}


.employee-summary-cards{
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 1rem;
}

.employee-summary-info{
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;
  margin: 2rem 0 0 4rem;
}

.employee-summary-chard-card{
  height: 300px;
}

@media only screen and (max-width: 812px) {
  .employee-summary-info{
    flex-direction: column;
    align-items: center;
  }

  .employee-summary-cards{
    flex-direction: column;
    align-items: center;
  }
}

