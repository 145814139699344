.time-filters {
  margin: 20px 0;
}

.time-filters span {
  background: #ebfef0 0% 0% no-repeat padding-box;
  border-radius: 18px;
  padding: 8px 25px;
  margin: 0 7px;
}

.time-filters span.active-filter {
  background: #004225 0% 0% no-repeat padding-box;
  color: white;
}

.date-range {
  flex-direction: column;
}

.range-selectors {
  display: flex;
}
