.personal-info-header {
    font-size: 40px;
    padding-left: 15px;
    border-left: 3px solid black;
    margin-top: 30px;
}
  
.personal-info-summary {
    margin-top: 30px;
    display: flex;
    flex-wrap: wrap;
}
  
.personal-info-summary .TimezoneSelect {
    outline: none;
    height: 80px !important;
    max-width: 522px !important;
    min-width: 400px !important;
    margin: 0 15px 30px 0 !important;
}
  
.personal-info-button {
    color: white;
    background-color: #004225;
    border: none;
    padding: 10px;
    border-radius: 2px;
    font-size: 16px;
}

.timezone-label{
    margin-bottom: 3px;
    text-align: center;
    color: rgb(25, 25, 25);
    font-size: 16px;
}
  
.personal-info-button:disabled{
    background-color: gray;
    color: black;
}

.personal-info-btns-box{
    display: flex;
    align-items: center;
    gap: 8px;
}

.letter-btn{
    margin: 16px 0 0 0;
    padding: 10px 15px;
    font-weight: bolder;
    background-color: #004225;
    color: white;
    border-radius: 2px;
}

.contract-btn{
    margin: 0 8px 0;
    padding: 10px 15px;
    font-weight: bolder;
    color: #004225;
    border-radius: 2px;
    transition: all .2s ease;
    border: 2px solid #004225;
}

.contract-btn:hover{
    color: #002013;
    border: 2px solid #002013;
}