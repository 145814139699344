.searchSection {
  width: 74px;
  background: #eaeaea 0% 0% no-repeat padding-box;
  border-radius: 45px 0px 0px 0px;
  min-height: 100%;
}

.searchText {
  transform: matrix(0, -1, 1, 0, 0, 0);
  color: #393939;
  top: 420px;
  position: absolute;
  font-size: 28px;
  left: -140px;
  opacity: 0.7;
  letter-spacing: 8.4px;
  text-align: left;
}

.searchIcon {
  padding-top: 72px;
  text-align: center;
}
