.download-toggle-option {
  cursor: pointer;
  background: white;
  border-radius: 25px;
  color: black;
  box-shadow: inset 0px 0px 10px #00000029, 0px 2px 10px #00000019;
  position: relative; 
}

.downloadbutton {
  width: 159px;
  height: 46px;
  background: white;
  border-radius: 50px;
  color: black;
  text-align: center;
  padding-top: 14px;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  text-transform: capitalize;
}

.download-toggle-option .download-options {
  width: 159px;
  display: none;
  position: absolute; 
  top: 100%; 
  left: 0;
  background: white;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000; 
  padding: 10px 0;
}

.download-toggle-option:hover .download-options,
.download-toggle-option:active .download-options {
  display: block;
}

.download-option {
  font-size: 16px;
  padding: 0 0 10px 15px;
  display: flex;
  align-items: flex-start;
  gap: 8px;
  flex-direction: column;
}

.download-options .download-option a {
  color: black;
  background-color: transparent;
  transition: all .2s ease-in;
}

.download-options .download-option a:hover {
  color: #004225;
  font-weight: bolder;
  transform: translateX(15px);
}
