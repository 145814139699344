.diaryHours {
  width: 100%;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(6, auto);
  margin-bottom: 100px;
  margin-left: 0;
}

@media only screen and (max-width: 1650px){
  .diaryHours {
    display: grid;
    grid-template-columns: repeat(5, auto);
  }
}

@media only screen and (max-width: 1370px){
  .diaryHours {
    display: grid;
    grid-template-columns: repeat(4, auto);
  }
}

@media only screen and (max-width: 1160px){
  .diaryHours {
    display: grid;
    grid-template-columns: repeat(3, auto);
  }
}

@media only screen and (max-width: 885px){
  .diaryHours {
    display: grid;
    grid-template-columns: repeat(2, auto);
  }
}

@media only screen and (max-width: 650px){
  .diaryHours {
    display: grid;
    grid-template-columns: repeat(1, auto);
  }
}
