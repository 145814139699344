.profile-box {
  height: 69px;
  background: #072f07;
  border-radius: 35px;
}

.profile-image {
  height: 50px;
  width: 50px;
  margin: 10px;
  border-radius: 50%;
  object-fit: cover
}

.profile-details {
  color: white;
  display: flex;
  flex-direction: column;
  margin-right: 20px;
}

.profile-name {
  font-size: 24px;
  line-height: 28px;
}

.designation {
  font-size: 16px;
  line-height: 19px;
}

@media screen and (max-width: 800px) {
  .profile-details {
    display: none;
  }
}

.profile-full:hover,
.profile-full:active {
  background: black;
  border-radius: 35px 35px 0 0;
}

.profile-box:hover,
.profile-box:active {
  background: black;
}

.profile-navigation {
  display: none;
  background: black;
  padding: 10px;
}

.profile-navigation a {
  color: white;
}

.profile-navigation div {
  margin: 5px 0 5px 10px;
}

.profile-box-small {
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
}

.profile-full:hover .profile-navigation {
  background: black;
  border-radius: 0 0 35px 35px;
  display: block;
}

.profile-full{
  z-index: 1000;
}
