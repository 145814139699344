.timezoneSelector > div {
  border: none !important;
  padding: 10px 15px;
}

@media screen and (min-width: 846px) {
  .css-1kwwvb1-ValueContainer2 {
    min-width: 150px;
  }
}

@media screen and (min-width: 989px) {
  .css-1kwwvb1-ValueContainer2 {
    min-width: 200px;
  }
}

@media screen and (min-width: 1149px) {
  .css-1kwwvb1-ValueContainer2 {
    min-width: 250px;
  }
}

@media screen and (min-width: 1291px) {
  .css-1kwwvb1-ValueContainer2 {
    min-width: 300px;
  }
}
