.dashboardPayrollMain {
  background-color: white;
  top: 100px;
  left: 75px;
  position: absolute;
  border-radius: 45px 0 0 0;
  width: calc(100vw - 75px);
  min-height: calc(100vh - 100px);
  padding: 10px 40px;
}

.dashboardPayrollMain-header {
  font-size: 40px;
  padding-left: 15px;
  border-left: 3px solid black;
  margin: 30px 0;
}

.weekSelector .react-datepicker__week:hover .react-datepicker__day {
  border-radius: 3px;
  color: white;
  font-weight: 600;
  background: #216ba5;
}

.monthSelector .react-datepicker__month:hover .react-datepicker__day {
  border-radius: 3px;
  color: white;
  font-weight: 600;
  background: #216ba5;
}

.react-datepicker__tab-loop {
  z-index: 25;
}

.payroll-table {
  margin-top: 20px;
}

.rdt_ExpanderRow .data-table-extensions,
.rdt_ExpanderRow .rdt_TableHeader,
.rdt_ExpanderRow .export-button,
.rdt_ExpanderRow .project {
  display: none !important;
}

.projectCheckbox {
  margin: 30px;
}

.rdt_ExpanderRow .rdt_Pagination:last-child {
  display: none !important;
}

.project {
  display: inline-block;
  margin: 20px;
}


.work-info-table .project {
  display: none;
}

.payroll-form{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
}

.payroll-form .payroll-form-btn{
  padding: 10px 15px;
  background-color: #216ba5;
  color: white;
  transition: all .2s ease-in-out;
  border-radius: 2px;
}

.payroll-form .payroll-form-btn:hover{
  background-color: #2987ce;
}