.dashboardMain {
  background-color: rgb(241, 241, 241);
  top: 100px;
  left: 75px;
  position: absolute;
  border-radius: 45px 0 0 0;
  width: calc(100vw - 75px);
  height: 810px;
  display: flex;
  overflow-y: auto;
}

.dashboard-image {
  max-width: 1000px;
  max-height: 670px;
  margin: auto;
}

.manager-dash-project-dropdown{
  grid-area: drop;
}

.users-table-header{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.users-table-header .table-filters{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
}

.manager-dash-box{
  overflow-y: auto;
  width: 90%;
  padding: 0 1rem 0;
  margin: 2rem auto;
  display: grid;
  gap: 1rem;
  grid-template-areas:
    "drop drop"
    "requests status"
    "compliance compliance"
    "chart chart"
    ;
}
.manager-dash-box::-webkit-scrollbar {
  width: 10px;
}

.manager-dash-requests-box{
  cursor: default;
  grid-area: requests;
  height: 465px;
  overflow-y: auto;
}

.manager-dashboard-requests{
  color: rgb(45, 45, 45);
  font-weight: bolder;
}

.requests-count{
  text-align: end;
  color: black;
  width: 100%;
}

.manualTime{
  padding: 5px;
  border-radius: 2px;
  color: white;
  background-color: #004225;
}

.overtime{
  padding: 5px;
  border-radius: 2px;
  color: white;
  background-color: #216BA5;
}

.manager-dash-usersStatus-box{
  cursor: default;
  grid-area: status;
  height: 465px;
  overflow-y: auto;
}

  
.manager-dash-chart-box{
  grid-area: chart;
}

.manager-dash-compliance-box{
  cursor: default;
  grid-area: compliance;
  height: 465px;
  overflow-y: auto;
}

.manager-dash-table-titles{
  font-weight: bolder;
}

@media only screen and (max-width: 1350px) {
  .manager-dash-box{
    overflow-y: auto;
    padding: 10px;
    grid-template-columns: 1fr;
    gap: 5rem;
    grid-template-areas: 
    "drop drop"
    "requests requests"
    "status status"
    "compliance compliance"
    "chart chart"
    ;
  }
}
