.summaryChartCard {
  max-width: 435px;
  height: 100%;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 15px #00000029;
  border-radius: 25px;
  margin-left: 10px;
  padding: 1rem;
}

.summaryChart {
  width: 400px;
  margin: -100px 0;
}

.summaryChartTitle {
  text-align: center;
  font-size: 25px;
  font-weight: 600;
}

.summarylegends {
  display: flex;
  flex-direction: column;
}

.summaryEmptyChart{
  font-weight: bolder;
  text-align: center;
  color: rgb(88, 88, 88);
  
}
