.screenshot-column img,
.camshot-column img {
  width: 780px;
  height: 480px;
}

.timecard-time {
  margin-bottom: 20px;
}

.timeCard-row {
  display: flex;
}

.screenshot-column {
  margin-right: 7px;
}

.timecard-summary {
  width: 780px;
  height: 84px;
  /* UI Properties */
  background: #f2f2f2 0% 0% no-repeat padding-box;
  border-radius: 0px 0px 25px 25px;
  margin-top: -4px;
  font-size: 24px;
}

.mouse-clicks,
.keystrokes {
  margin-top: 28px;
  margin-right: 20px;
}

.key-logo {
  width: 35px !important;
  height: 35px !important;
  margin: 18px 15px;
  color: gray;
}

.productivity-indicator {
  width: 191px;
  height: 48px;
  /* UI Properties */
  background: #3cbe52 0% 0% no-repeat padding-box;
  border-radius: 29px;
  margin: 17px 20px;
  text-align: center;
  color: white;
  padding-top: 10px;
}

.activity-summary {
  width: 780px;
  height: 84px;
  /* UI Properties */
  border-radius: 0px 0px 25px 25px;
  margin-top: -4px;
  font-size: 30px;
  text-align: center;
  padding-top: 24px;
  color: white;
}

.other-images {
  display: flex;
  margin-top: 50px;
}

.other-image {
  margin: 0 6px;
}

.other-image img {
  width: 180px !important;
  height: 115px !important;
}

.chevron {
  width: 23px;
  height: 53px;
  margin: auto;
}
