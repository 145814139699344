.layout-container{
    height: 90vh;
    display: flex;
    align-items: center;
    justify-content: space-between;
    overflow-y: scroll;
}

.layout-container .layout-outlet{
    padding: 1rem;
    height: calc(100% - 10vh);
    overflow-y: scroll;
    align-self: flex-start;
    width: 100%;
    background-color: white;
}