
.user-rate{
    margin-top: 16px;
}

.user-rate label{
    font-weight: bolder;

}

.save-webtracker-btn{
    border-radius: 2px;
    margin-left: 5px;
    border: none;
    outline: none;
    background-color: #004225;
    color: white;
    font-weight: bolder;
    padding: 10px 15px;
}

.save-webtracker-btn:disabled{
    background-color: #808080;
    color: black;
    font-weight: bolder;
}


.web-tracker-status-select{
    width: 150px;
    padding: 10px 15px;
    border-radius: 4px;
    margin: 8px 0;
}