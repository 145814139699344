.tracker-component{
  margin-top: 10px;
}

.tracker-toggle-option {
    cursor: pointer;
    background: white;
    border-radius: 25px;
    color: black;
    box-shadow: inset 0px 0px 10px #00000029, 0px 2px 10px #00000019;
  }
  
  .trackerbutton {
    background-color: white;
    color: black;
    border-radius: 50px;
    height: 45px;
    padding-left: 30px;
    padding-right: 30px;
    border: none;
    transition: all .3s ease-in;
    font-weight: bolder;
  }

  .trackerbutton:hover{
    -webkit-box-shadow: -2px 3px 5px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: -2px 3px 5px 0px rgba(0,0,0,0.75);
    box-shadow: -2px 3px 5px 0px rgba(0,0,0,0.75);
  }
  
  .tracker-toggle-option .tracker-options {
    display: none;
  }
  
  .tracker-toggle-option:hover .tracker-options,
  .tracker-toggle-option:active .tracker-options {
    display: block;
  }
  #modal-modal-title{
    border-bottom: 2px solid gray;
    padding: 8px 0;
  }

  .tracker-weekly-detail{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .tracker-daily-detail{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .tracker-projects{
    width: 100%;
    padding: 5px;
    text-align: center;
    font-weight: bolder;
  }

  .tracker-projects:disabled{
    cursor: default;
    background-color: rgb(206, 206, 206);
    color: black;
  }

  .modal-controllers{
    margin-top: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .modal-controllers .close-tracker-modal-btn{
    color: rgb(153, 4, 4);
    font-weight: bolder;
    cursor: pointer;
    background-color: transparent;
    transition: all .2s ease;
  }

  .modal-controllers .close-tracker-modal-btn:hover{
    color: rgb(202, 54, 54);
  }

  small{
    color: rgb(153, 4, 4);
    font-weight: bolder;
  }


  #modal-description{
    border: none;
  }
  
  .last-update-label{
    text-align: center;
    padding: 15px;
  }

  .last-update-label  .last-update-moment{
    font-weight: bolder;
  }

  .tracker-popper{
    padding: 5px;
    z-index: 99999;
  }

  .tracker-popper-header{
    padding: 20px;
    text-align: center;
  }

  .tracker-popper-content{
    padding: 20px;
  }