.rdt_TableCol_Sortable,
.rdt_TableCell {
  justify-content: center;
}

.rdt_TableHeadRow div {
  justify-content: center;
  white-space: pre-wrap;
}

.rdt_TableHeader {
  padding: 0.7rem 0;
  width: 20% !important;
}

.rdt_TableHeader,
.rdt_TableHeader .sc-ieecCq.gwlQOp {
  display: block !important;
  float: right;
}

.rdt_TableHeader .sc-ieecCq.gwlQOp {
  width: 60% !important;
}

.data-table-extensions > .data-table-extensions-filter > .icon {
  background-image: url("https://img.icons8.com/ios-filled/20/undefined/search--v1.png");
}

.data-table-extensions > .data-table-extensions-action {
  display: none;
}

.data-table-extensions {
  width: 50%;
  float: left;
  
  margin: 0px 0px 10px 0px;
 
  padding: 0px;
}

.data-table-extensions-filter {
  border: 1px solid #004225;
  border-radius: 18px;
  padding: 12px;
  width: 100%;
  height: 50px;
}

.data-table-extensions > .data-table-extensions-filter > .filter-text {
  border-bottom: none;
  font-size: 18px;
  padding-top: 2px;
}

a.export-button {
  background-color: #fff;
  border-radius: 18px;
  margin: 25px;
  color: #004225;
  text-align: center;
  font-size: 18px;
  padding: 10px 20px;
  text-transform: capitalize;
  border: 2px solid #004225;
}

.data-table-extensions > .data-table-extensions-filter {
  padding: 12px;
}

.rdt_TableCell div:first-child{
  white-space: normal !important; 
  overflow: visible !important;
  text-overflow: clip !important; 
  text-align: center;
}