.productivityCard {
  height: 90px;
  /* UI Properties */
  background: #f2f2f2 0% 0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
  display: flex;
  flex-direction: column;
  margin:5px 5px;
  text-align: center;
}


.settings{
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.mouse,
.keys {
  font-size: 12px;
  text-align: right;
  color: black;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 40px;
  padding: 5px;
}

.mouse svg,
.keys svg {
  color: #8d8d8d;
}

.productivityType {
  font-size: 12px;
  text-align: center;
  color: white;
  width: 100%;
  height: 30px;
  /* UI Properties */

  padding-top: 5px;
  background: #3cbe52 0% 0% no-repeat padding-box;
  border-radius: 15px;
  opacity: 1;
  margin: auto 0;
}

img[alt="keystrokes"] {
  width: 12px;
}
