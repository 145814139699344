.search-button {
  position: absolute;
  top: 33px;
  left: 30px;
  width: 459px;
  height: 85px;
  background: #dddddd 0% 0% no-repeat padding-box;
  border-radius: 25px;
  text-align: center;
}

.search-button-text {
  display: flex;
  margin: 0 auto;
  font-size: 24px;
  color: #afafaf;
  padding: 10px 15px;
  justify-content: space-around;
  align-items: center;
}

input {
  background-color: transparent;
  font-size: 1em;
  border-color: transparent;
}

input:focus {
  border-color: transparent;
  outline: none;
}

