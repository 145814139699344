.employee-sidebar {
  background-color: #eaeaea;
  border-radius: 45px 0 0 0;
  overflow-y: auto;
  position: sticky;
  height: 810px;
}

.sidebar-search-users-footer{
  background-color: rgba(0, 0, 0, 0.781);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0;
  position: fixed;
  bottom: 0;
  width: 402px;
}

.sidebar-search-users-footer button{
  padding: 10px 15px;
  border-radius: 2px;
  outline: none;
  font-weight: bolder;
  color: white;
  background-color: #004225;
}

.employee-cards-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 8px;
  padding: 5px 10px;
  box-sizing: border-box;
}

.onlyActive {
  margin-top: 16px;
  font-weight: bolder;
  display: flex;
  align-items: center;
  justify-content: center;
  
}

.onlyActive label {
  margin-right: 10px;
}


.sidebar-search-button{
  text-align: center;
  padding: 20px 10px;
  margin: 16px;
}

.search-input{
  border: 1px solid rgb(122, 122, 122);
  border-radius: 5px;
  width: 80%;
  background-color: rgb(226, 226, 226);
}


.team-employee-cards-list {
  margin-top: 150px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 8px;
  padding: 5px 10px;
  overflow-x: hidden;
  overflow-y: auto;
  height: calc(100vh - 100px);
  box-sizing: border-box;
}

.loading-icon{
  width: 100%;
  text-align: center;
}

.users-counter{
  margin-top: 8px;
  font-size: 14px;
  font-weight: bolder;
  color: #004225;
}

.sideBarSearchBtn{
  cursor: pointer;
  background: none;
  background-color: transparent;
  
}

.sideBarSearchBtn:disabled{
  cursor: default;
}

.loading-div{
  display: flex;
  align-items: center;
  justify-content: center;
}
