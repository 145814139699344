.profile-subheader{
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 16px 80px;
  background-color: #02fa8e0c;
  margin-bottom: 16px;
}

.profile-links {
  font-family: Raleway, sans-serif;
  font-size: 30px;
  color: #8d8d8d;
  display: flex;
  align-items: center;
  gap: 16px;
}


.profile-links li a {
  text-decoration: none;
  color: #8d8d8d;
}

.profile-links li a.active {
  border-bottom: solid 2px #004225;
  color: #004225;
}

@media screen and (max-width: 1360px) {
  .profile-links{
    font-size: 25px;
  }
}

@media screen and (max-width: 1285px) {
  .profile-links{
    font-size: 25px;
    flex-direction: column;
    gap: 0;
  }

  .profile-links li{
    margin: 8px 0;
  }
}

