.notification-container {
  position: relative;
  display: flex;
  align-items: center;
}

.notification-icon {
  cursor: pointer;
  position: relative;
  color: white;
}

.notification-count {
  position: absolute;
  top: -5px;
  right: -5px;
  background-color: red;
  color: white;
  padding: 2px 6px;
  border-radius: 15px;
  font-size: 12px;
}

.notification-dropdown {
  display: none; 
  position: absolute;
  top: 50px;
  right: 0;
  background-color: white;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  width: 400px;
  max-height: 250px;
  border-radius: 5px;
  z-index: 1000;
  flex-direction: column;
}

.notification-list {
  list-style: none;
  padding: 10px;
  margin: 0;
  overflow-y: auto;
  max-height: 200px; 
  flex-grow: 1; 
  text-align: center;
}

.notification-list li {
  padding: 0;  
  cursor: pointer;
}

.notification-list li button {
  background: none;
  display: block;          
  padding: 10px;          
  width: 100%;             
  box-sizing: border-box;  
  color: rgb(45, 45, 45);
  font-weight: bolder;
  text-decoration: none;  
}

.notification-list li:hover button {
  background-color: #f1f1f1;
}

.notification-container:hover .notification-dropdown {
  display: flex; 
}

.notification-container.disabled {
  pointer-events: none;
  opacity: 0.5; 
}

.notification-container.disabled .notification-icon {
  cursor: not-allowed;
}

.notifications-footer {
  background-color: rgb(226, 226, 252);
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid #ddd;
  flex-shrink: 0; 
}

.notifications-clear-all-btn {
  background-color: transparent;
  width: 100%;
  padding: 10px 15px;
  border: none;
  color: rgb(84, 84, 212);
  font-weight: bolder;
  cursor: pointer;
  text-align: center;
}

.notification-list li .read{
  color: gray;
}