.diaryTimeCard {
  height: 100%;
  width: 250px;
  box-shadow: 0px 2px 15px #00000019;
  border-radius: 15px;
  opacity: 1;
  padding: 10px 5px;
}

.diary-shots {
  display: flex;
  justify-content: space-evenly;
}

