.sidebar {
  width: 75px;
  display: flex;
  padding-top: 1rem;
  gap: 2rem;
  height: 90vh;
  flex-direction: column;
  background-color: #004225;
}

.sidebar svg {
  margin: 10px 5px;
}

.sidebar svg path {
  stroke: gray;
}

.active svg,
.active div {
  color: white !important;
}

.active svg path {
  stroke: white;
}

.nav-group svg {
  margin: 5px 0;
  height: 30px !important;
  width: 30px !important;
}


.team-nav-group div{
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: .2rem;
}

.team-nav-group svg {
  margin: 5px 0;
  height: 30px !important;
  width: 30px !important;
}

#employee-sidebar .Tooltip-Tip.right {
  left: calc(40% + var(--tooltip-margin));
}

.versionLogo {
  color: white;
  position: fixed; 
  bottom: 0px;
  left: 0px;
  text-align: center;
  width: 75px;
}

.versionLogo img {
  width: 80%;
  height: auto;
}
