.ManualTimeApproval {
    background-color: white;
    top: 100px;
    left: 75px;
    position: absolute;
    border-radius: 45px 0 0 0;
    width: calc(100vw - 75px);
    min-height: calc(100vh - 100px);
    padding: 10px 40px;
  }
  
  .dashboardManualTimeApproval-header {
    font-size: 40px;
    padding-left: 15px;
    border-left: 3px solid black;
    margin: 30px 0;
  }
  
  .filters{
    width: 100%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 0;
    margin: 24px 0;
  }

  .filters div{
    margin: 0 8px;
  }

  .filters div .filter-mt-button{
    border: none;
    outline: none;
    background-color: #004225;
    color: white;
    font-weight: bold;
    border-radius: 2px;
    padding: 12px;
  }

  .basic-single{
    line-height: 2.4;
    z-index: 800;
  }

  .project-selection{
    padding: 12px 15px;
    width: 300px;
    border-radius: 3px;
    color: #808A94;
    outline: none;
    border: 1px solid rgb(185, 185, 185);
  }