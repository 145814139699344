.settingsContainer {
    background-color: white;
    top: 100px;
    left: 75px;
    position: absolute;
    border-radius: 45px 0 0 0;
    width: calc(100vw - 75px);
    height: calc(100% - 10vh);
    margin: 0 auto;
    overflow-y: scroll;
    display: flex;
  }
.settingsBody {
    margin-left: 10em;
    margin-top: 5em;
}

.txtField {
    width: 100%;
}

.ssoSettings > div {
    width: 60%;
    margin: 10px 100px 20px 100px;
}

.input {
    display: block;
}

.settingsLabel {
    background-color: gray;
    color: whitesmoke;
    padding: .4em;
}

.saveBtn { 
    height: 66px;
    background-color: #004225;
    border-radius: 18px;
    margin-left: 1vw;
    margin-bottom: 25px;
    color: #fff;
    text-align: center;
    font-size: 25px;
    font-family: Roboto, sans-serif;
    padding: 0px 25px;
    float: right;
}

.ssoSwitchLabel {
    margin-right: 10px;
    vertical-align: super;
    font-size: 18px;
}