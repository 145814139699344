.project-modal {
  padding: 1em;
  display: block;
  top: 5vh;
  left: 10vw;
  width: 70vw;
  height: 90vh;
  z-index: 5000;
}

.project-headers {
  margin: 25px 0 15px;
  font-size: 30px;
  padding-left: 10px;
}

.project-details-card .downloadButtons {
  display: none;
}

.project-modal .close-modal {
  margin-left: 45vw;
  float: right;
  font-size: large;
}

.project-details-card {
  padding: 0 20px;
  z-index: -5000;
}

.project-card {
  display: flex;
  flex-wrap: wrap;
}

#section-project-members {
  overflow-y: scroll;
}

.project-details-card .project-headers {
  margin-bottom: 25px;
}

.project-input-with-label {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  width: 415px;
}

.project-input-with-label input[type='checkbox'] {
  height: 30px;
  width: 30px;
}

.project-input-with-label .input-text {
  min-width: 100px;
}

.project-label {
  width: 300px;
  font-size: 20px;
  margin: 5px 10px;
}

.project-input-with-label #project-hours {
  width: 150px;
  margin: 0;
}

.project-modal .remove-member,
.project-modal #add-project-member-button {
  background-color: #fff;
  border-radius: 15px;
  margin: 10px 20px;
  color: #004225;
  text-align: center;
  font-size: 20px;
  padding: 5px 20px;
  text-transform: capitalize;
  width: 200px;
  border: 2px solid #004225;
  display: inline-block;
}

.project-modal #add-project-member-button {
  width: 50px;
  padding: 5px;
  border: none;
}

.project-modal #add-project-member-button:hover {
  color: white;
  background-color: rgba(0, 66, 37, 0.3);
}

#add-project-member {
  margin: 5px 15px;
  border: 2px solid #004225;
  border-radius: 18px;
  width: 400px;
  display: inline-block;
}

#add-project-member input {
  background: none;
  padding: 15px;
}

#add-project-member input:active,
#add-project-member input:focus {
  border: none;
  border-style: none;
  outline: none;
}

#add-project-member .suggestion {
  border: none;
}

.project-info-button {
  color: white;
  background-color: #004225;
  border: none;
  padding: 10px;
  border-radius: 15px;
  font-size: 25px;
  margin: 20px 0 5px 20px;
}


.dateSelector {
  display: inline-block;
  width: 350px;
  height: 60px;
}

.dateSelector small {
  margin-left: 20px;
}

.react-datepicker-popper {
  z-index: 222;
}

.data-table-extensions-filter {
  width: 455px;
}

.data-table-extensions {
  width: auto;
}

.project {
  padding: 0px !important;
  margin: 10px;
}

.dateSelector:has(input[type="text"]:disabled) {
  background: #dddddd;
}