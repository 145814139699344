.add-project-modal-container{
    background-color: rgba(0, 0, 0, 0.199);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999;
}

.add-project-modal-container .add-project-modal-box{
    padding: 1rem 2rem;
    background-color: white;
    border-radius: 10px;
    border: 2px solid #D1D5DB;
}

.add-project-modal-container .add-project-modal-box .add-project-modal-title{
    text-align: center;
}

.add-project-modal-container .add-project-modal-box .add-project-modal-form{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
}

.add-project-modal-container .add-project-modal-box .add-project-modal-form .add-project-modal-input-box{
    margin: 1rem 0;
}

.add-project-modal-container .add-project-modal-box .add-project-modal-form .add-project-modal-input-box .add-project-modal-input{
    border: 1px solid #D1D5DB;
}

.add-project-modal-container .add-project-modal-box .add-project-modal-form .add-project-modal-input-box .add-project-modal-input-number{
    border: 1px solid #D1D5DB;
    padding: 1rem 0;
    border-radius: 7px;
}

.add-project-modal-container .add-project-modal-box .add-project-modal-form .add-project-modal-input-box .add-project-modal-dropdown{
    width: 200px;
}

.add-project-modal-container .add-project-modal-box .add-project-modal-form .add-project-modal-hour-and-shared{
    display: flex;
    align-items: center;
    gap: 1rem;
}
.add-project-modal-container .add-project-modal-box .add-project-modal-form .add-project-modal-shared-input{
    display: flex;
    align-items: center;
    gap: .5rem;
}

.add-project-modal-container .add-project-modal-box .add-project-modal-form .add-project-modal-btns{
    display: flex;
    align-items: center;
    gap: 1rem;
}