.links {
  font-family: Raleway, sans-serif;
  font-size: 30px;
  line-height: 35px;
  color: rgb(45, 45, 45);
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.subheader-links{
  height: 217px;
}

.links ul {
  display: flex;
  gap: 1rem;
  margin-right: 2rem;
  height: 100%;
}

.links ul li a {
  text-decoration: none;
  color: #8d8d8d;
}

.links ul li a.active {
  border-bottom: solid 2px #004225;
  color: #004225;
}

.manager-subheader-links{
  display: flex;
  font-size: 1.9rem;
  align-items: center;
  justify-content: flex-end;
  margin-right: 5rem;
}

.manager-subheader-links ul {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  height: 100%;
}

.manager-subheader-links ul li a {
  text-decoration: none;
  color: #8d8d8d;
}

.manager-subheader-links ul li a.active {
  border-bottom: solid 2px #004225;
  color: #004225;
}


@media screen and (max-width: 1570px){
  .manager-subheader-links{
    font-size: 1.5rem;
  }
}

@media screen and (max-width: 1440px){
  .manager-subheader-links{
    font-size: 1rem;
    font-weight: bolder;
  }
}

@media screen and (max-width: 1280px){
  .manager-subheader-links{
    font-size: 1.5rem;
    font-weight: normal;
  }

  .manager-subheader-links ul{
    display: flex;
    flex-direction: column;
    gap: .5rem;
  }

  .manager-subheader-links ul li{
    margin: 0;
    padding: 0;
  }
}

@media screen and (max-width: 1570px){
  .links{
    font-size: 1.5rem;
  }
}

@media screen and (max-width: 1440px){
  .links{
    font-size: 1rem;
    font-weight: bolder;
  }
}

@media screen and (max-width: 1280px){
  .links{
    font-size: 1.5rem;
    font-weight: normal;
  }

  .links ul{
    display: flex;
    flex-direction: column;
    gap: .5rem;
  }

  .links ul li{
    margin: 0;
    padding: 0;
  }
}