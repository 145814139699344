.team-members-modal-page{
    z-index: 999;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 40px 0 0 0;
    background-color: rgba(0, 0, 0, 0.096);
    width: 100%;
    height: 100%;
}

.team-members-modal-page .team-members-modal-box{
    background-color: white;
    border-radius: 5px;
    padding: 3rem;
    width: 70%;
}

.project-team-members-headers{
    font-size: 1.5rem;
    font-weight: bolder;
}

.add-team-member-to-project{
    padding: 1rem .5rem;
    display: flex;
    align-items: center;
    gap: .5rem;
}

.add-team-member-icon{
    cursor: pointer;
}

.team-members-remove-agent-btn{
    background-color: rgb(240, 76, 17);
    font-weight: bolder;
    padding: .5rem 1rem;
    border-radius: 2px;
    color: white;
}

.team-members-modal-btn{
    margin-top: 1rem;
    text-align: end;
}

.team-members-close-modal-btn{
    background-color: #003C22;
    font-weight: bolder;
    padding: .5rem 1rem;
    border-radius: 2px;
    color: white;
}

.auto-complete-dropdown{
    border: 1px solid #D1D5DB;
    border-radius: 6px;
}