.requests-forms-btns{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
    margin: 1rem 0;
}

.requests-forms-btns .request-mt-button{
    background-color: #004225;
    color: white;
    transition: all .2s ease-in-out;
    padding: 5px 10px;
}
.requests-forms-btns .request-mt-button:hover{
    background-color: #05683d;
}

.requests-forms-btns .request-ot-button{
    background-color: #216ba5;
    color: white;
    transition: all .2s ease-in-out;
    padding: 5px 10px;
}
.requests-forms-btns .request-ot-button:hover{
    background-color: #2987ce;
}

.request-mt-button:disabled{
    background-color: gray;
    color: white;
    cursor: default;
}

.request-ot-button:disabled{
    background-color: gray;
    color: white;
    cursor: default;
}

.delete-button{
    background-color: rgb(192, 8, 8);
    color: white;
    font-weight: bolder;
}

.manual-hours-buttons .cancel-button{
    background-color: transparent;
    padding: 0 10px;
    color: orangered;
    font-weight: bolder;
}

.reject-reason-col{
    word-wrap: break-word;
    color: #FF0000;
    font-weight: bolder;
}

.reason-col{
    white-space: normal;
    overflow: visible;
    text-overflow: clip;
    word-wrap: break-word;
}
