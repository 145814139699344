.react-datepicker__input-container {
  padding: 5px;
}

.dateSelector,
.weekSelector,
.monthSelector {
  border: solid 1px #004225;
  border-radius: 23px;
  display: flex;
  align-items: center;
  padding: 0 10px;
  margin: 0 5px 5px;
  height: 70px;
  width: 350px;
}

.no-border,
.no-border:active {
  border: none;
}
.calendar_date {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: black;
  font-size: 20px;
}

.calendar_icon {
  color: #004225;
  cursor: pointer;
  width: 35px;
  height: 35px;
}

.date-range {
  display: flex;
}
