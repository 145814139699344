.employee-card {
  margin-top: 16px;
  background: transparent linear-gradient(320deg, #ffb703 0%, #c37b67 100%) 0%
    0% no-repeat padding-box;
  border-radius: 2px;
  color: white;
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 10px;
  font-weight: bolder;
  width: 300px;
  height: 130px;
}

.employee-card .card-header{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.employee-card .card-header .card-image{
  height: 50px;
  width: 50px;
}


