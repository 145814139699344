.project-modal {
  padding: 1em;
  display: block;
  top: 5vh;
  left: 10vw;
  width: 50vw;
}

.project-details-modal {
  padding: 1em;
  display: block;
  top: 15vh;
  width: 850px;
}

.project-details-card .downloadButtons {
  display: none;
}

.project-modal .close-modal {
  margin-left: 45vw;
}

.project-details-card {
  padding: 0 20px;
}
.project-details-card .project-team-headers{
  font-size: 24px;
  margin: 80px 0 16px 0;
}

.project-card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 1rem;
}

.project-card .project-card-config{
  display: flex;
  align-items: center;
}

.project-card .project-card-compliance-config{
  display: flex;
  align-items: center;
}

.project-card .project-card-extra-config{
  display: flex;
  align-items: center;
}

#section-project-members {
  overflow-y: scroll;
  height: 500px;
}

.project-details-input-with-label {
  margin: 0 .5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
}

.project-details-input-with-label input[type='checkbox'] {
  height: 30px;
  width: 30px;
}

.project-details-input-with-label .input-text {
  min-width: 100px;
}

.project-label {
  width: 300px;
  font-size: 20px;
  margin: 5px 10px;
}

.project-details-input-with-label #project-hours {
  width: 150px;
  margin: 0;
}

.project-modal .remove-member,
.project-modal #add-project-member-button {
  background-color: #fff;
  border-radius: 2px;
  margin: 10px 20px;
  color: #004225;
  text-align: center;
  font-size: 20px;
  padding: 5px 20px;
  text-transform: capitalize;
  width: 200px;
  border: 2px solid #004225;
  display: inline-block;
}

.project-modal #add-project-member-button {
  width: 50px;
  padding: 5px;
  border: none;
}

.project-modal #add-project-member-button:hover {
  color: white;
  background-color: rgba(0, 66, 37, 0.3);
}

#add-project-member {
  margin: 5px 15px;
  border: 2px solid #004225;
  border-radius: 2px;
  width: 270px !important;
  display: inline-block;
  margin-bottom: 20px !important;
  vertical-align: top;
  height: 50px;
  margin-top: 10px !important;
}

#add-project-member input {
  background: none;
  padding: 15px;
}

#add-project-member input:active,
#add-project-member input:focus {
  border: none;
  border-style: none;
  outline: none;
}

#add-project-member .suggestion {
  border: none;
}

.update-project-button {
  color: white;
  background-color: #004225;
  border: none;
  padding: 10px;
  border-radius: 2px;
  font-size: 16px;
  margin: 20px 0 5px 20px;
}

.update-project-button:disabled{
  background-color: rgb(221, 221, 221);
}

.close-modal-button {
  color: white;
  background-color: #F04C11;
  border: none;
  padding: 10px;
  border-radius: 2px;
  font-size: 16px;
  margin: 20px 0 5px 20px;
}

.project-card div.input-block input,.project-card div.input-block {
  width: 180px;
  min-width: auto;
  margin-right: 10px;
  height: 50px;
}

div.input-block > * {
  font-size: 16px !important;
}

.data-table-extensions-filter {
  width: 280px !important;
  margin-top: 10px;
}

.input-text {
min-width: auto;
}


#autocomplete-input {
  width: 220px !important;
  font-size: 12px !important;
}

#add-project-member-button {
  width: 20px !important;
  padding: 0px !important;
  margin: 5px !important;
}

.project {
  margin-left: 10px !important;
  margin-top: 10px !important;
  display: inline-block;
}

.project a {
  margin: 0px !important;
  display: inline-block;
 
}

#add-project-btn:disabled{
  opacity: .4;
  cursor: default;
  background-color: #00a85f;
}

.btn-and-alert{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.btn-and-alert small{
  color: red;
  font-weight: bolder;
}

.manager-select-box{
  display: flex;
  flex-direction: column;
}

.manager-select-box label{
  margin-bottom: 3px;
}
.manager-selector{
  text-align: center;
  outline: 1px solid black;
  background-color: transparent;
  font-weight: bolder;
  padding: 12px 15px;
  border-radius: 2px;
  color: black;
}

.disable-btn{
  margin: 0 .5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.add-project-input-with-label{
  display: flex;
  align-items: center;
  gap: 8px;
  margin: 16px 0 0 8px;
  font-weight: bolder;
}

.add-project-input-with-label input{
  height: 30px;
  width: 30px;
}


.project-card-btns{
  padding-right: 1rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

@media only screen and (max-width: 1240px){
  .project-details-modal {
    left: 20vh;
  }
  
}

@media only screen and (max-width: 1040px){
  .project-details-modal {
    left: 10vh;
  }
  
}