.basic-single{
    width: 300px;
}

.user-rate{
    margin-top: 16px;
}

.user-rate label{
    font-weight: bolder;
}

.user-rate input{
    margin: 5px 0;
    background-color: rgb(158, 158, 158);
    color: white;
    font-weight: bolder;
    padding: 8px;
    border-radius: 2px;
    width: 240px;
    line-height: 3;
}

.frequency-drop{
    width: 300px;
    padding: 10px 0;
    line-height: 2.4;
}

.user-rate .save-rate-btn{
    border-radius: 2px;
    margin: 8px 0;
    padding: 10px 15px;
    border: none;
    outline: none;
    background-color: #004225;
    color: white;
    font-weight: bolder;
}

.rate-table{
    overflow-y: auto;
    width: 90%;
    height: 500px;
    z-index: 0;
    padding: 0 20px;
    margin-bottom: 20px;
}