.admin-subheader-links {
    font-family: Raleway, sans-serif;
    font-size: 30px;
    line-height: 35px;
    color: #8d8d8d;
    position: absolute;
    right: 40px;
  }
  
  .admin-subheader-links ul {
    padding: 10px;
    display: inline-flex;
  }
  
  .admin-subheader-links ul li {
    display: flex;
    padding: 0 10px;
  }
  
  .admin-subheader-links ul li a {
    text-decoration: none;
    color: #8d8d8d;
  }
  
  .admin-subheader-links ul li a.active {
    border-bottom: solid 2px #004225;
    color: #004225;
  }
  
  
  @media screen and (max-width: 1155px){
    .admin-subheader-links{
      font-size: 1.5rem;
    }
  }
  @media screen and (max-width: 990px){
    .admin-subheader-links{
      font-size: .8rem;
      font-weight: bolder;
    }
  }
  