.input-text {
  max-width: 522px;
  min-width: 400px;
  /* UI Properties */
  border: 1px solid #004225;
  border-radius: 2px;
  margin-bottom: 30px;
  font-size: 25px;
  color: black;
  padding: 5px 10px;
  margin-right: 15px;
}

div.input-block {
  position: relative;
}
div.input-block input {
  font-weight: 500;
  width: 350px;
  padding: 15px 15px;
  outline: none;
}

div.input-block span.placeholder {
  position: absolute;
  margin: 17px 0;
  padding: 10px 10px;
  font-family: Roboto, sans-serif;
  color: #6c757d;
  display: flex;
  align-items: center;
  font-size: 1.6rem;
  top: 0;
  left: 17px;
  transition: all 0.2s;
  transform-origin: 0% 0%;
  background: none;
  pointer-events: none;
}

input:valid + span.placeholder,
input:focus + span.placeholder {
  transform: scale(0.8) translateY(-30px);
}
input:focus {
  color: #004225;
  border-color: #004225;
}

input:focus + span.placeholder {
  color: #004225;
}
