.links-box{
  background-color: white;
  top: 100px;
  left: 75px;
  position: absolute;
  border-radius: 45px 0 0 0;
  width: calc(100vw - 75px);
  height: calc(100% - 10vh);
}
.employee-main-work-info {
    overflow-y: scroll;
    padding-left: 90px;
  }


  .work-info-header {
    font-size: 40px;
    padding-left: 15px;
    border-left: 3px solid black;
    margin-top: 30px;
    margin-bottom: 30px;
  }
  
  .work-info-table {
    font-size: 18px !important;
    overflow: hidden;
  }
  
  .team-member-detail-profile {
    display: flex;
    align-items: center;
  }
  
  .team-member-detail-image {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    margin: 10px;
  }
  
  .team-member-detail {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  
  .work-info-table .rdt_TableCol_Sortable,
  .work-info-table .rdt_TableCell {
    justify-content: flex-start !important;
  }
  
  .work-info-table .rdt_TableHeadRow .rdt_TableCol_Sortable {
    justify-content: center !important;
  }
  
  .work-info-table .rdt_TableRow {
    min-height: 100px;
  }
  
  .work-info-table [data-column-id="1"].rdt_TableCol {
    width: 30%;
  }

  .work-info-table nav {
    position: relative;
    z-index: 2000;
  }

  ul{
    list-style: none;
  }
  
  ul li{
    margin: 16px 0;
  }

  ul li .extern-links{
    text-decoration: none;
    color: black;
    cursor: pointer;
    font-weight: bolder;
    transition: all .2s ease-in-out;
  }

  ul li .extern-links:hover{
    color: #00693c;
  }
  