.summary {
  background-color: white;
  top: 100px;
  left: 75px;
  position: absolute;
  border-radius: 45px 0 0 0;
  width: calc(100vw - 75px);
  min-height: calc(100vh - 100px);
  display: flex;
}

.summaryMain {
  overflow-x: hidden;
  height: calc(100vh - 220px);
  margin-left: 1rem;
}

.summaryDiary {
  padding-left: 62px;
  position: absolute;
  width: 90vw;
}

.summaryContent {
  overflow-y: auto;
  height: calc(100vh - 440px);
  padding: 10px;
}

.selectors-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-end;
}

.selectors-column {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.summaryHeader {
  padding: 16px;
  font-size: 36px;
  color: black;
  margin: 10px 0;
  font-weight: 600;
}

.selectors {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-end;
}

.summaryCards {
  padding: 0 5px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.productivity-timeline {
  margin-top: 15px;
}

.Clock-Charts {
  display: flex;
  width: 300px;
}

.graphs-section {
  margin-top: 20px;
}

.graphs {
  display: flex;
  flex-wrap: wrap;
  padding: 0 40px;
  margin: 30px;
  justify-content: space-around;
}

.loading-label{
  margin: 20px;
  width: 30px;
  height: 30px;
  font-weight: bolder;
}

.summaryDiary-adm-page{
  margin-top: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}
