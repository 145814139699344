.header {
  height: 100px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center; 
  padding: 0 10px; 
  z-index: 500; 
  background-color: #004225;
}

.download-button {
  margin: 10px;
  z-index: 600; 
  position: relative;
}

.align-left {
  display: flex;
  align-items: center;
  margin-left: 70px;
  margin-bottom: 10px;
}

.pageName {
  color: white;
  font-size: 36px;
  padding: 15px;
}

.align-right {
  display: flex;
  margin: 19px 2%;
  gap: 16px;
}

.wisdom-logo {
  width: 76px;
  height: 76px;
}
