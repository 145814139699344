.diarySummary {
  overflow-y: scroll;
  height: calc(100vh - 250px);
}

.diary div {
  vertical-align: middle;
}

.no-data-msg {
  width: 100%;
  text-align: center;
  font-style: italic;
  font-size: medium;
  color: gainsboro;
  background-color: gray;
  font-weight: bolder;
  margin: auto;
  margin-top: 5%;
  border-radius: 10px;
}

.loader {
  margin-left: 10px;
  color: antiquewhite;
}

.diary .dateSelector {
  margin-right: 30px;
}