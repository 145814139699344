.diarySummary {
  overflow-y: scroll;
  height: calc(100vh - 250px);
}

.diaryMain{
  padding: 1rem 2rem;
}

.timecard-action-box{
  display: flex;
  align-items: center;
  gap: 8px;
}

.timecards-action-btns{
  border-radius: 2px;
  padding: 10px 15px;
  transition: all .2s ease-in;
}

.timezoneSelector {
  display: inline-block;
  width: 450px;
  height: 60px;
}
.filter{
  display: flex;
  align-items: center;
}

#select-all-cards{
  background-color: #3560C8;
  font-weight: bolder;
  color: white;
}

#select-all-cards:hover{
  background-color: #4875df;
}

#delete-cards{
  background-color: rgb(180, 28, 28);
  font-weight: bolder;
  color:white;
}
#delete-cards:hover{
  background-color: rgb(216, 36, 36);
}

#active-cards{
  background-color: #004225;
  font-weight: bolder;
  color: white;
}
#active-cards:hover{
  background-color: #02643a;
}

#timecard-status{
  background-color: #da5a11;
  font-weight: bolder;
  color: white;
}
#timecard-status:hover{
  background-color: #fc7222;
}

#update-cards{
  background-color: #3560C8;
  color: white;
}
#update-cards:hover{
  background-color: #4173e9;
  color: white;
}
#update-cards:disabled{
  background-color: rgb(199, 198, 198);
  color: white;
}

.diary-cards-title{
  margin: 16px 0;
}

#delete-cards:disabled{
  background-color: gray;
  color: white;
}

#active-cards:disabled{
  background-color: gray;
  color: white;
}


/* Estilização geral para a tabela */
table.timecard-dialog {
  width: 100%;
  border-collapse: collapse; /* Para garantir que as bordas se juntem de forma uniforme */
  font-family: Arial, sans-serif; /* Fonte limpa e legível */
  margin-top: 10px;
}

/* Estilização para o cabeçalho */
thead.timecard-dialog th.timecard-dialog {
  background-color: #444; /* Cor de fundo chumbo */
  color: white; /* Texto branco */
  padding: 12px 15px; /* Espaçamento interno */
  text-align: center; /* Texto centralizado */
  font-weight: bold;
}

/* Estilização para as células da tabela */
td.timecard-dialog {
  padding: 10px 15px; /* Espaçamento interno das células */
  text-align: center; /* Texto centralizado */
  border: 1px solid #ddd; /* Borda leve nas células */
}

/* Borda para separar as linhas da tabela */
tbody.timecard-dialog tr.timecard-dialog:nth-child(odd) {
  background-color: #f9f9f9; /* Fundo alternado para facilitar a leitura */
}

tbody.timecard-dialog tr.timecard-dialog:hover {
  background-color: #f1f1f1; /* Cor de fundo ao passar o mouse */
}

/* Estilo do botão */
button.ok-dialog-btn {
  margin-top: 10px;
  padding: 10px 20px;
  background-color: #007bff; /* Cor do botão */
  color: white; /* Texto branco */
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  float: right;
}

button.ok-dialog-btn:hover {
  margin-right: 0;
  background-color: #0056b3; /* Cor de hover para o botão */
}