/* Estilos gerais do modal */
.compliance-modal {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 9999;
}

.compliance-modal-box {
    background: #fff;
    padding: 25px;
    border-radius: 10px;
    width: 750px;
    max-width: 95%;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.3);
    display: flex;
    flex-direction: column;
}

.compliance-modal h4 {
    text-align: center;
    margin-bottom: 15px;
    font-size: 1.6rem;
    font-weight: bold;
    color: #333;
}

.compliance-modal-form {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

/* Grid para os campos básicos */
.compliance-modal-item-header {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 12px;
}

.compliance-modal-drop-body {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 12px;
}
.compliance-modal-item-body {
    display: grid;
    grid-template-columns: 1fr;
    gap: 12px;
}

/* Inputs e labels alinhados */
.compliance-modal label {
    font-size: 0.9rem;
    font-weight: 600;
    color: #444;
    margin-bottom: 5px;
}

.compliance-modal input,
.compliance-modal textarea,
.compliance-modal select {
    width: 100%;
    padding: 10px;
    font-size: 0.9rem;
    border: 1px solid #ccc;
    border-radius: 5px;
    background: #f8f8f8;
}

/* Área de Compliance Detail */
#compliance-detail {
    height: 80px;
    resize: none;
}

/* Seção de revisão e status - ocupa 100% da largura e espaçada corretamente */
.compliance-item-body-info {
    display: flex;
    gap: 20px;
    justify-content: space-between;
    width: 100%;
}

.compliance-item-body-info div {
    flex: 1;
    display: flex;
    flex-direction: column;
}

/* Garante que os selects e inputs tenham o mesmo tamanho */
.compliance-item-body-info select,
.compliance-item-body-info input {
    width: 100%;
    padding: 10px;
    font-size: 0.9rem;
    border: 1px solid #ccc;
    border-radius: 5px;
    background: #f9f9f9;
}

/* Seção de comentários */
.compliance-modal-item-discussion-closures {
    display: flex;
    gap: 12px;
    width: 100%;
}

.compliance-modal-item-discussion-closures div {
    flex: 1;
}

/* Botões */
.compliance-form-btns {
    display: flex;
    justify-content: flex-end;
    gap: 12px;
    margin-top: 10px;
}

.compliance-form-btn-submit {
    background: #28a745;
    color: white;
    border: none;
    padding: 10px 16px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
    transition: 0.3s;
}

.compliance-form-btn-submit:disabled {
    background: #ccc;
    cursor: not-allowed;
}

.compliance-form-btn-reset {
    background: #dc3545;
    color: white;
    border: none;
    padding: 10px 16px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
    transition: 0.3s;
}

.compliance-form-btn-reset:hover {
    background: #c82333;
}
