.additional-payment-modal{
    top: 30vh;
    left: 30vw;
    height: 40vh;
    width: 25vw;
    box-shadow: -4px 2px 39px -2px rgba(0,0,0,0.79);
    -webkit-box-shadow: -4px 2px 39px -2px rgba(0,0,0,0.79);
    -moz-box-shadow: -4px 2px 39px -2px rgba(0,0,0,0.79);
}

.project-card .select{
    width: 375px;
}

.project-card .select select{
    line-height: 1;
    padding: 0 5;
}

.user-rate{
    display: flex;
    flex-direction: column;
    align-items: start;
}

.user-rate div label{
    width: 70px;
}

.user-rate input{
    line-height: 2;
    width: 300px;
    background-color: #808080;
}

.user-rate .select-div{
    margin-top: 16px;
}

.user-rate .select-div label{
    margin-right: 5px;
}

.user-rate select{
    line-height: 2;
    border-radius: 2px;
    width: 300px;
}

.btn-and-alert{
    padding: 0;
    margin-left: 0;
}

.btn-and-alert button{
    margin: 8px 0;
    text-transform: uppercase;
    font-weight: bolder;
    font-size: 16px;
    border-radius: 2px;
    padding: 5px 10px;

}

@media only screen and (max-width: 1320px) {
    .add-payment-form input{
        width: 150px;
    }

    .add-payment-form select{
        width: 150px;
    }
  }

  @media only screen and (max-width: 1065px) {
    .additional-payment-modal{
        height: 45vh;
    }

  }