.landing-header {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 40px 170px;
}

.landing-logo {
  width: 310px;
  height: 130px;
}


.landing-header-link {
  font-size: 30px;
  margin: 0 15px;
  color: black !important;
}

.landing-header-link:hover {
  color: #004225 !important;
}

.landing-center {
  display: flex;
}

.landing-center-text {
  font-size: 35px;
  color: #707070;
  margin: 0 0 0 170px;
}

.landing-center-text-header {
  font-size: 64px;
  font-weight: 600;
  padding-top: 100px;
  padding-bottom: 30px;
  color: black;
}

.landing-text {
  width: 80%;
  line-height: 1.25;
  padding-bottom: 60px;
  margin-bottom: 20px;
}

.landing-center-image-right {
  width: 50vw;
  height: 70vh;
  margin: 0 130px 0 0;
}

a.landing-center-button {
  background: #004225 0% 0% no-repeat padding-box;
  border-radius: 18px;
  color: white;
  padding: 25px 16px;
  text-transform: uppercase;
  margin: 50px 0;
  font-size: 25px;
}

.landing-footer {
  background-color: black;
  position: absolute;
  bottom: 0;
  width: 100%;
  color: #e5e5e5;
  text-align: right;
  padding: 10px;
}
